import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

import ObesitySurgeryImage from "../images/original/tüp mide.jpg";
import GastricBypass from "../images/original/gastrik baypas.jpg";
import MiniGastricBypass from "../images/original/mini gastrik baypas.jpg";
import GastricBalon from "../images/original/gastrik balon.jpg";
import { TreatmentPage } from "components/TreatmentPage";

export const ObesitySurgeryPage = () => {
  const treatments = [
    {
      subheading: "tube-stomach-treatment",
      mainText: "tube-stomach-treatment-main",
      descriptions: [
        "tube-stomach-treatment-1",
        "tube-stomach-treatment-2",
        "tube-stomach-treatment-3",
      ],
      imageSrc: ObesitySurgeryImage,
    },
    {
      subheading: "gastric-bypass",
      mainText: "gastric-bypass-main",
      descriptions: [
        "gastric-bypass-1",
        "gastric-bypass-2",
        "gastric-bypass-3",
        "gastric-bypass-4",
        "gastric-bypass-5",
        "gastric-bypass-6",
      ],
      imageSrc: GastricBypass,
    },
    {
      subheading: "mini-gastric-bypass",
      mainText: "mini-gastric-bypass-main",
      descriptions: [
        "mini-gastric-bypass-1",
        "mini-gastric-bypass-2",
        "mini-gastric-bypass-3",
        "mini-gastric-bypass-4",
        "mini-gastric-bypass-5",
        "mini-gastric-bypass-6",
        "mini-gastric-bypass-7",
      ],
      imageSrc: MiniGastricBypass,
    },
  ];

  const subsections = [
    {
      type: "subsection",
      text: "obezite-cerrahisi",
      descriptions: ["non-surgical-1", "non-surgical-2"],
      cards: [
        {
          imageSrc: ObesitySurgeryImage,
          title: "tube-stomach-treatment",
          link: "/tube-stomach-treatment",
        },
        {
          imageSrc: GastricBypass,
          title: "gastric-bypass",
          link: "/gastric-bypass",
        },
        {
          imageSrc: MiniGastricBypass,
          title: "mini-gastric-bypass",
          link: "/mini-gastric-bypass",
        },
      ],
    },
    {
      type: "subsection",
      text: "non-surgical",
      descriptions: ["non-surgical-1", "non-surgical-2"],
      cards: [
        {
          imageSrc: GastricBalon,
          title: "non-surgical-title",
          link: "/gastric-balloon",
        },
      ],
    },
  ];

  return (
    <TreatmentPage
      header="obezite-cerrahisi"
      treatments={treatments}
      subsections={subsections}
    />
  );
};

import React from "react";
import FaceLift from "../../../images/original/face-lift.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "face-lift",
    mainText: "face-lift-main",
    descriptions: [
      "face-lift-1",
      "face-lift-2",
      "face-lift-3",
      "face-lift-4",
      "face-lift-5",
      "face-lift-6",
      "face-lift-7",
      "face-lift-8",
      "face-lift-9",
    ],
    imageSrc: FaceLift,
  },
];
export const FaceLiftPage = () => {
  return <CustomPage header="face-lift" details={details} />;
};

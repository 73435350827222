import { CustomPage } from "components/CustomPage";
import React from "react";
import DhiHairTransplant from "../images/original/DHI saç ekimi.jpg";

export const DhiHairPage = () => {
  const details = [
    {
      subheading: "dhi-hair-transplant",
      mainText: "dhi-hair-transplant-main",
      descriptions: ["dhi-hair-transplant-1", "dhi-hair-transplant-2"],
      imageSrc: DhiHairTransplant,
    },
  ];
  return <CustomPage header="dhi-hair-transplant" details={details} />;
};

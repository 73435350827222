import React from "react";
import GastricBypassImage from "../images/original/gastrik baypas.jpg";
import { CustomPage } from "components/CustomPage";
const details = [
  {
    subheading: "gastric-bypass",
    mainText: "gastric-bypass-main",
    descriptions: [
      "gastric-bypass-1",
      "gastric-bypass-2",
      "gastric-bypass-3",
      "gastric-bypass-4",
      "gastric-bypass-5",
      "gastric-bypass-6",
    ],
    imageSrc: GastricBypassImage,
  },
];

export const GastricBypass = () => {
  return <CustomPage header="gastric-bypass" details={details} />;
};

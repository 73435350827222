import React from "react";
import EyelidLiftImage from "../../../images/original/eyelid_lift.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "eyelid-lift",
    mainText: "eyelid-lift-main",
    descriptions: [
      "eyelid-lift-1",
      "eyelid-lift-2",
      "eyelid-lift-3",
      "eyelid-lift-4",
    ],
    imageSrc: EyelidLiftImage,
  },
];
export const EyelidLiftPage = () => {
  return <CustomPage header="eyebrow-lift" details={details} />;
};

import React from "react";
import SurgicalNeckLift from "../../../images/original/surgical_neck_lift.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "surgical-neck-lift",
    mainText: "surgical-neck-lift-main",
    descriptions: ["surgical-neck-lift-1", "surgical-neck-lift-2"],
    imageSrc: SurgicalNeckLift,
  },
];
export const SurgicalNeckLiftPage = () => {
  return <CustomPage header="eyebrow-lift" details={details} />;
};

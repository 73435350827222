import React from "react";
import TummyTuckImage from "../../../images/original/tummy_tuck.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "tummy-tuck",
    mainText: "tummy-tuck-main",
    descriptions: [
      "tummy-tuck-1",

      {
        type: "list",
        listTexts: [
          "tummy-tuck-list-1",
          "tummy-tuck-list-2",
          "tummy-tuck-list-3",
          "tummy-tuck-list-4",
        ],
      },
      "tummy-tuck-2",
      "tummy-tuck-3",
      "tummy-tuck-4",
      "tummy-tuck-5",
    ],
    imageSrc: TummyTuckImage,
  },
];
export const TummyTuckPage = () => {
  return <CustomPage header="non-surgical-face-lift" details={details} />;
};

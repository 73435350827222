import { createGlobalStyle } from "styled-components";
import { globalStyles } from "twin.macro";

export const GlobalStyles = createGlobalStyle(
  globalStyles,
  `
     .ReactModal__Overlay {
     transition: transform 300ms ease-in-out;
     transition-delay: 100ms;
     transform: scale(0);
   }
   .ReactModal__Overlay--after-open{
     transform: scale(1);
   }
   .ReactModal__Overlay--before-close{
     transform: scale(0);
   }
`
);

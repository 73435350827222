import React from "react";
import MiniGastricBypassImage from "../images/original/mini gastrik baypas.jpg";
import { CustomPage } from "components/CustomPage";
const details = [
  {
    subheading: "mini-gastric-bypass",
    mainText: "mini-gastric-bypass-main",
    descriptions: [
      "mini-gastric-bypass-1",
      "mini-gastric-bypass-2",
      "mini-gastric-bypass-3",
      "mini-gastric-bypass-4",
      "mini-gastric-bypass-5",
      "mini-gastric-bypass-6",
      "mini-gastric-bypass-7",
    ],
    imageSrc: MiniGastricBypassImage,
  },
];

export const MiniGastricBypass = () => {
  return <CustomPage header="gastric-bypass" details={details} />;
};

import React from "react";
import WhiteningImage from "../../../images/original/whitening.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "whitening",
    mainText: "whitening-main",
    descriptions: ["whitening-1"],
    imageSrc: WhiteningImage,
  },
];
export const Whitening = () => {
  return <CustomPage header="eyebrow-lift" details={details} />;
};

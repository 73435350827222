import React from "react";
import VajinoplastiImage from "../../../images/original/vajinoplasti.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "vajinoplasti",
    mainText: "vajinoplasti-main",
    descriptions: [
      "vajinoplasti-1",
      "vajinoplasti-2",
      "vajinoplasti-3",
      "vajinoplasti-4",
      "vajinoplasti-5",
      "vajinoplasti-6",
      "vajinoplasti-7",
    ],
    imageSrc: VajinoplastiImage,
  },
];
export const Vajinoplasti = () => {
  return <CustomPage header="non-surgical-face-lift" details={details} />;
};

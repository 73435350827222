import React from "react";
import HollywoodSmile from "../../../images/original/hollywood.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "hollywood",
    mainText: "hollywood-main",
    descriptions: ["hollywood-1", "hollywood-2", "hollywood-3"],
    imageSrc: HollywoodSmile,
  },
];
export const Hollywood = () => {
  return <CustomPage header="eyebrow-lift" details={details} />;
};

import React from "react";
import NonSurgicalFaceLiftImage from "../../../images/original/non_surgical_facelift.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "non-surgical-facelift",
    mainText: "non-surgical-facelift-main",
    descriptions: [
      "non-surgical-facelift-1",
      "non-surgical-facelift-2",
      {
        type: "list",
        listTexts: [
          "non-surgical-facelift-list-1",
          "non-surgical-facelift-list-2",
          "non-surgical-facelift-list-3",
          "non-surgical-facelift-list-4",
          "non-surgical-facelift-list-5",
          "non-surgical-facelift-list-6",
          "non-surgical-facelift-list-7",
        ],
      },
      "non-surgical-facelift-3",
      "non-surgical-facelift-4",
    ],
    imageSrc: NonSurgicalFaceLiftImage,
  },
];
export const NonSurgicalFaceLiftPage = () => {
  return <CustomPage header="non-surgical-face-lift" details={details} />;
};

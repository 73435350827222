import "slick-carousel/slick/slick.css";
import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import loveIllustrationImageSrc from "images/love-illustration.svg";
import { ReactComponent as StarIconBase } from "images/star-icon.svg";
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";
import { useTranslation } from "react-i18next";
import { Heading } from "components/features/DashedBorderSixFeatures";

const Row = tw.div`flex flex-col md:flex-row justify-between items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 xl:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 xl:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:pr-12 lg:pr-16 md:order-first`
    : tw`md:pl-12 lg:pl-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

// const Heading = tw(
//   SectionHeading
// )`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const TestimonialSlider = styled(Slider)`
  ${tw`w-full mt-10 text-center md:text-left`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;

const Testimonial = tw.div`outline-none h-full flex! flex-col`;
const StarsContainer = styled.div``;
const StarIcon = tw(
  StarIconBase
)`inline-block w-5 h-5 text-orange-400 fill-current mr-1 last:mr-0`;

const Quote = tw.blockquote`mt-4 mb-8 sm:mb-10 leading-relaxed font-medium text-gray-700`;

const CustomerInfoAndControlsContainer = tw.div`mt-auto flex justify-between items-center flex-col sm:flex-row`;

const CustomerInfo = tw.div`flex flex-col sm:flex-row items-center justify-center lg:justify-start`;

const CustomerTextInfo = tw.div`text-center md:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerName = tw.h5`font-bold text-xl`;

const Controls = styled.div`
  ${tw`flex mt-8 sm:mt-0`}
  .divider {
    ${tw`my-3 border-r`}
  }
`;
const ControlButton = styled.button`
  ${tw`mx-3 p-4 rounded-full transition duration-300 bg-gray-200 hover:bg-gray-300 text-primary-500 hover:text-primary-700 focus:outline-none focus:shadow-outline`}
  svg {
    ${tw`w-4 h-4 stroke-3`}
  }
`;

export const ClientComments = ({
  imageSrc = loveIllustrationImageSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  textOnLeft = false,
  testimonials = [
    {
      stars: 5,
      quote:
        "Fiz meu hair implante com a acessória da empresa da Antonela o fato de eu ter conhecido a empresa me ajudou muito com a decisão de fazer o implante pois ela me deu toda segurança de uma clínica que ela já conhece ela me explicou todo passo a passo na cirurgia é isso me deixou confortável e seguro do meu investimento em um país que eu até então não conhecia, após a cirurgia ela me deu todo suporte quando precisei com a cicatrização primeiras lavagens é isso com certeza foi o melhor que podia fazer para ter meu implante perfeito. Obrigado Antonela",
      customerName: "Sergio",
    },
    {
      stars: 5,
      quote:
        "Bis eine Freundin mir Magnuvia empfohlen hat, war ich eine schwierige Patientin und hatta ich grosse Angst var dem Zahnarzt. Frau Cotos von Magnuvia hat mich wirklich gut geleitet und mich Meisterhänden anvertraut. Für die Zirkon Kronen musste ich in Deutschland min. 4-6 Monaten einplanen und min. 2 fach mehr bezahlen. Magnuvia hat für mich alles bestens in İstanbul organisiert. Neben meiner Behandlung hatte ich Möglichkeit İstanbul zu entdecken. Meine Behandlung war schmerzlos und ziemlich schnell. Ich kann jetzt endlich besser lächeln. Ein grosses Danke Schön zur Frau Cotos, dass sie sich gut um mich gekümmert und auf richtigen Weg geführt hat.",
      customerName: "Heike",
    },
    {
      stars: 5,
      quote:
        "Ich war eigentlich nur für ein kurze Info Vorgespräch bezüglich einer Haartransplantatiin Magnuvia und fand mich am nächsten Woche auf dem OP-Tisch wieder. Frau Cotos war sehr nett, sie hat mich über alle alternativen informiert und wir haben meine prozess zusammen  geplant und sie hat für mich alle  schritte vorbereitet. Endlich mal war ich in Istanbul Die Leiterin der Klinik, begrüßte mich sehr freundlich und zuvorkommend und besprach mit mir alle Schritte rund um die Operation. Auch ein Arzt war an der Beratung beteiligt und beantwortete meine Fragen. Die Behandlung verlief sehr gut und ohne Komplikationen. Das ganze Team, vom Empfang bis zum Chef, war hochmotiviert. Aufjedenfall ich war in guten Händen. Ich würde jederzeit wieder in diese Klinik gehen und weitersagen. Nochmals ein großes Kompliment an Magnuvia und ihr Team. Danke für alles.",
      customerName: "Andree",
    },
    {
      stars: 5,
      quote:
        "În timp ce eram în vacanță în Turcia, am dat peste Magnuvia în timp ce căutam o clinică pentru a ne facem botox şi liplifting . Domnişoara Antonela ne-a informat despre tot, iar eu mi-am făcut procedurile în clinica pe care mi-a recomandat-o. Am fost foarte mulțumită atât de ea, cât și de clinica în care mi-am făcut procedurile. Vă mulțumesc.",
      customerName: "Cazac A.",
    },
    {
      stars: 5,
      quote:
        "Am contactat la Magnuvia pe recomandare. Am decis să îmi fac fațetele dentare în Turcia, deoarece tratamentele dentare sunt foarte scumpe în țara mea. Doamna Antonela a planificat totul pentru mine și mi-a dat recomandări de vacanță pentru Istanbul. Am fost foarte ușurată de faptul că vorbea aceeași limbă cu mine. De fiecare dată când am sunat-o, mi-a răspuns la telefon și mi-a răspuns la întrebări. O recomand tuturor.",
      customerName: "Rodica",
    },
    {
      stars: 5,
      quote:
        "I got help from Magnuvia to have a hair transplant. I was very pleased with the options they offered me and the counselling they gave me. It was nice to be supported before and after the procedure. I recommend it to everyone.",
      customerName: "R.W.",
    },
  ],
}) => {
  const [sliderRef, setSliderRef] = useState(null);
  const { t } = useTranslation("common");

  return (
    <Container>
      <ContentWithPaddingXl>
        <Row>
          <ImageColumn>
            <Image
              src={imageSrc}
              imageBorder={imageBorder}
              imageShadow={imageShadow}
              imageRounded={imageRounded}
            />
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <Heading>{t("client-comments")}</Heading>

            <TestimonialSlider arrows={false} ref={setSliderRef}>
              {testimonials &&
                testimonials.map((testimonial, index) => (
                  <Testimonial key={index}>
                    <StarsContainer>
                      {Array.from({ length: testimonial.stars }).map(
                        (_, indexIcon) => (
                          <StarIcon key={indexIcon} />
                        )
                      )}
                    </StarsContainer>
                    <Quote>{testimonial.quote}</Quote>
                    <CustomerInfoAndControlsContainer>
                      <CustomerInfo>
                        <CustomerTextInfo>
                          <CustomerName>
                            {testimonial.customerName}
                          </CustomerName>
                        </CustomerTextInfo>
                      </CustomerInfo>
                      <Controls>
                        <ControlButton onClick={sliderRef?.slickPrev}>
                          <ArrowLeftIcon />
                        </ControlButton>
                        <div className="divider" />
                        <ControlButton onClick={sliderRef?.slickNext}>
                          <ArrowRightIcon />
                        </ControlButton>
                      </Controls>
                    </CustomerInfoAndControlsContainer>
                  </Testimonial>
                ))}
            </TestimonialSlider>
          </TextColumn>
        </Row>
      </ContentWithPaddingXl>
    </Container>
  );
};

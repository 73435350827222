import React from "react";
import GastricBalon from "../images/original/gastrik balon.jpg";
import { CustomPage } from "components/CustomPage";
const details = [
  {
    subheading: "non-surgical-title",
    mainText: "non-surgical-main",
    descriptions: ["non-surgical-1", "non-surgical-2"],
    imageSrc: GastricBalon,
  },
];

export const GastricBalloon = () => {
  return <CustomPage header="gastric-bypass" details={details} />;
};

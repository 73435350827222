import React from "react";
import ButtSurgeryImage from "../../../images/original/butt_surgery.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "butt-surgery",
    mainText: "butt-surgery-main",
    descriptions: ["butt-surgery-1", "butt-surgery-2"],
    imageSrc: ButtSurgeryImage,
  },
];
export const ButtSurgery = () => {
  return <CustomPage header="non-surgical-face-lift" details={details} />;
};

import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";

import KapaliCarsi from "../../images/original/kapali_carsi.jpg";
import SokakLezzetleri from "../../images/original/sokak_lezzetleri.jpg";
import BogazTuru from "../../images/original/bogaz_turu.jpg";
import YereBatan from "../../images/original/yerebatan.jpg";
import Büyükada from "../../images/original/büyükada.jpg";
import TurkishFood from "../../images/original/turkish_food.jpg";
import GalataKulesi from "../../images/original/galata.jpg";
import PeraPalace from "../../images/original/perapalace.jpg";
import Dolmabahce from "../../images/original/dolmabahçe.jpg";
import KızKulesi from "../../images/original/kiz_kulesi.jpg";
import { useTranslation } from "react-i18next";
import { Heading } from "./DashedBorderSixFeatures";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-700 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-xl font-bold text-primary-500`;
const Description = tw.p`mt-2 md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-700`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

const cards = [
  {
    imageSrc: KapaliCarsi,
    title: "kapali-carsi",
    descriptions: ["kapali-carsi-1"],
  },
  {
    imageSrc: SokakLezzetleri,
    title: "sokak-lezzetleri",
    descriptions: ["sokak-lezzetleri-1", "sokak-lezzetleri-2"],
  },
  {
    imageSrc: BogazTuru,
    title: "bogaz-turu",
    descriptions: ["bogaz-turu-1"],
  },
  {
    imageSrc: YereBatan,
    title: "yerebatan",
    descriptions: ["yerebatan-1"],
  },
  {
    imageSrc: Büyükada,
    title: "buyukada",
    descriptions: ["buyukada-1"],
  },
  {
    imageSrc: TurkishFood,
    title: "turkish-food",
    descriptions: ["turkish-food-1"],
  },
  {
    imageSrc: GalataKulesi,
    title: "galata",
    descriptions: ["galata-1", "galata-2"],
  },
  {
    imageSrc: PeraPalace,
    title: "pera-palace",
    descriptions: ["pera-palace-1", "pera-palace-2", "pera-palace-3"],
  },
  {
    imageSrc: Dolmabahce,
    title: "dolmabahce",
    descriptions: ["dolmabahce-1"],
  },
  {
    imageSrc: KızKulesi,
    title: "kiz-kulesi",
    descriptions: ["kiz-kulesi-1", "kiz-kulesi-2", "kiz-kulesi-3"],
  },
];

export const PopularEvents = () => {
  const { t } = useTranslation("common");

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <Heading>{t("popular-events")}</Heading>
          <HeadingDescription>{t("popular-events-desc")}</HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards &&
            cards.map((card, i) => (
              <Card key={i} reversed={i % 2 === 1}>
                <Image imageSrc={card.imageSrc} />
                <Details>
                  <Title>{t(card.title)}</Title>
                  {card.descriptions.map((d) => {
                    return <Description>{t(d)}</Description>;
                  })}
                </Details>
              </Card>
            ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};

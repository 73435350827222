import { CustomHero } from "components/CustomPage";
import React from "react";
import { AboutMe } from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import { MainFooter } from "components/footers/MiniCenteredFooter.js";
import { AnimationRevealPage } from "helpers/AnimationRevealPage";
export const About = () => {
  return (
    <AnimationRevealPage>
      <CustomHero header="about" />
      <AboutMe />
      <MainFooter />
    </AnimationRevealPage>
  );
};

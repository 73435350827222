import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import {
  NavLink,
  NavLinks,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
  MainHeader,
} from "../headers/light.js";

import { useTranslation } from "react-i18next";
import { VideoEmbed } from "helpers/ResponsiveVideoEmbed.js";

const StyledHeader = styled(MainHeader)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url("https://images.unsplash.com/photo-1522071901873-411886a10004?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80");
  background-fit: contain;
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-50`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32  flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-500 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const Span = tw.span`
text-xs md:text-4xl
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2 text-xs md:text-4xl`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-white-rgba30 transform -skew-x-12 -z-10`}
  }
`;

const StyledHeroText = tw.p`text-gray-500 mt-5 text-xs7 md:text-base`;

const StyledResponsiveVideoEmbed = styled(VideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

export const MainHero = () => {
  const { t } = useTranslation("common");
  const navLinks = [
    <NavLinks key={2}>
      <NavLink to="/about">{t("about")}</NavLink>
      <NavLink to="/what-to-do-in-istanbul">
        {t("what-to-do-in-istanbul")}
      </NavLink>
    </NavLinks>,
  ];
  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn
          style={{
            gap: "50px",
          }}
        >
          <LeftColumn
            style={{
              width: "60%",
            }}
          >
            <Heading>
              <Span>{t("health-tourism-1")}</Span>
              <br />
              <SlantedBackground>{t("health-tourism-2")}</SlantedBackground>
            </Heading>
            <StyledHeroText>{t("hero-info-text-1")}</StyledHeroText>
            <StyledHeroText>{t("hero-info-text-2")}</StyledHeroText>
          </LeftColumn>
          <RightColumn>
            <StyledResponsiveVideoEmbed
              url="videos/introduction.mp4"
              background="transparent"
            />
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading } from "components/misc/Headings.js";
import { useTranslation } from "react-i18next";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 md:rotate-85 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 md:translate-x-0 rotate-45 translate-y-64 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 md:translate-x-40 rotate-90 md:rotate-30 -translate-y-40 -z-10 opacity-25 text-primary-500 fill-current w-24`;

const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

export const Subheading = tw(
  SectionHeading
)`text-center md:text-left font-bold text-primary-500 `;

const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-700`;

export const WhyMagnuvia = ({ subheading = "why-magnuvia" }) => {
  const { t } = useTranslation("common");

  return (
    <Container>
      <TwoColumn>
        <TextColumn textOnLeft={true}>
          <TextContent>
            <Subheading>{t(subheading)}</Subheading>
            <Description>{t("why-magnuvia-1")}</Description>
            <Description>{t("why-magnuvia-2")}</Description>
            <Description>{t("why-magnuvia-3")}</Description>
            <Description>{t("why-magnuvia-4")}</Description>
            <Description>{t("why-magnuvia-5")}</Description>
          </TextContent>
        </TextColumn>
        <div style={{ position: "relative" }}>
          <SvgDotPattern1 />
          <SvgDotPattern2 />
          <SvgDotPattern3 />
          <SvgDotPattern4 />
        </div>
      </TwoColumn>
    </Container>
  );
};

import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

import { MainHero } from "components/hero/BackgroundAsImage.js";
import { Heading, Services } from "components/features/DashedBorderSixFeatures";
import { WhyMagnuvia } from "components/cards/PortfolioTwoCardsWithImage.js";
import { MainFooter } from "components/footers/MiniCenteredFooter.js";
import { useTranslation } from "react-i18next";

import { ClientComments } from "components/testimonials/TwoColumnWithImageAndRating";
import styled from "styled-components";
import { ContactForm } from "components/forms/SimpleContactUs";
import { AnimationRevealPage } from "helpers/AnimationRevealPage";

const OtherServicesContainer = styled.div`
  text-align: center;
`;

const OtherServicesUl = styled.ul`
  margin-top: 30px;
`;

const OtherServicesListItem = styled.li`
  font-weight: bold;
  color: #4a5568;
  font-size: 1.2rem;
`;

const OtherServices = () => {
  const { t } = useTranslation("common");

  return (
    <OtherServicesContainer>
      <Heading>{t("other-services")}</Heading>
      <OtherServicesUl>
        <OtherServicesListItem>{t("burun-dolgusu")}</OtherServicesListItem>
        <OtherServicesListItem>{t("göz-alti")}</OtherServicesListItem>
        <OtherServicesListItem>{t("gamze-estetik")}</OtherServicesListItem>
        <OtherServicesListItem>{t("dudak-kaldirma")}</OtherServicesListItem>
        <OtherServicesListItem>{t("mezoterapi")}</OtherServicesListItem>
        <OtherServicesListItem>{t("cilt-genclestirme")}</OtherServicesListItem>
        <OtherServicesListItem>{t("ozon-terapi")}</OtherServicesListItem>
        <OtherServicesListItem>{t("botox")}</OtherServicesListItem>
      </OtherServicesUl>
    </OtherServicesContainer>
  );
};

export const HomePage = () => (
  <AnimationRevealPage>
    <MainHero />
    <WhyMagnuvia />
    <Services />
    <OtherServices />

    <ClientComments
      testimonials={[
        {
          stars: 5,
          quote:
            "Fiz meu hair implante com a acessória da empresa da Antonela o fato de eu ter conhecido a empresa me ajudou muito com a decisão de fazer o implante pois ela me deu toda segurança de uma clínica que ela já conhece ela me explicou todo passo a passo na cirurgia é isso me deixou confortável e seguro do meu investimento em um país que eu até então não conhecia, após a cirurgia ela me deu todo suporte quando precisei com a cicatrização primeiras lavagens é isso com certeza foi o melhor que podia fazer para ter meu implante perfeito. Obrigado Antonela",
          customerName: "Sergio",
        },
        {
          stars: 5,
          quote:
            "Bis eine Freundin mir Magnuvia empfohlen hat, war ich eine schwierige Patientin und hatta ich grosse Angst var dem Zahnarzt. Frau Cotos von Magnuvia hat mich wirklich gut geleitet und mich Meisterhänden anvertraut. Für die Zirkon Kronen musste ich in Deutschland min. 4-6 Monaten einplanen und min. 2 fach mehr bezahlen. Magnuvia hat für mich alles bestens in İstanbul organisiert. Neben meiner Behandlung hatte ich Möglichkeit İstanbul zu entdecken. Meine Behandlung war schmerzlos und ziemlich schnell. Ich kann jetzt endlich besser lächeln. Ein grosses Danke Schön zur Frau Cotos, dass sie sich gut um mich gekümmert und auf richtigen Weg geführt hat.",
          customerName: "Heike",
        },
        {
          stars: 5,
          quote:
            "Ich war eigentlich nur für ein kurze Info Vorgespräch bezüglich einer Haartransplantatiin Magnuvia und fand mich am nächsten Woche auf dem OP-Tisch wieder. Frau Cotos war sehr nett, sie hat mich über alle alternativen informiert und wir haben meine prozess zusammen  geplant und sie hat für mich alle  schritte vorbereitet. Endlich mal war ich in Istanbul Die Leiterin der Klinik, begrüßte mich sehr freundlich und zuvorkommend und besprach mit mir alle Schritte rund um die Operation. Auch ein Arzt war an der Beratung beteiligt und beantwortete meine Fragen. Die Behandlung verlief sehr gut und ohne Komplikationen. Das ganze Team, vom Empfang bis zum Chef, war hochmotiviert. Aufjedenfall ich war in guten Händen. Ich würde jederzeit wieder in diese Klinik gehen und weitersagen. Nochmals ein großes Kompliment an Magnuvia und ihr Team. Danke für alles.",
          customerName: "Andree",
        },
        {
          stars: 5,
          quote:
            "În timp ce eram în vacanță în Turcia, am dat peste Magnuvia în timp ce căutam o clinică pentru a ne facem botox şi liplifting . Domnişoara Antonela ne-a informat despre tot, iar eu mi-am făcut procedurile în clinica pe care mi-a recomandat-o. Am fost foarte mulțumită atât de ea, cât și de clinica în care mi-am făcut procedurile. Vă mulțumesc.",
          customerName: "Cazac A.",
        },
        {
          stars: 5,
          quote:
            "Am contactat la Magnuvia pe recomandare. Am decis să îmi fac fațetele dentare în Turcia, deoarece tratamentele dentare sunt foarte scumpe în țara mea. Doamna Antonela a planificat totul pentru mine și mi-a dat recomandări de vacanță pentru Istanbul. Am fost foarte ușurată de faptul că vorbea aceeași limbă cu mine. De fiecare dată când am sunat-o, mi-a răspuns la telefon și mi-a răspuns la întrebări. O recomand tuturor.",
          customerName: "Rodica",
        },
        {
          stars: 5,
          quote:
            "I got help from Magnuvia to have a hair transplant. I was very pleased with the options they offered me and the counselling they gave me. It was nice to be supported before and after the procedure. I recommend it to everyone.",
          customerName: "R.W.",
        },
      ]}
    />
    <ContactForm />
    <MainFooter />
  </AnimationRevealPage>
);

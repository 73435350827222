import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

import FueHairTransplant from "../images/original/FUE saç ekimi.jpg";
import DhiHairTransplant from "../images/original/DHI saç ekimi.jpg";
import { TreatmentPage } from "components/TreatmentPage";

const treatments = [
  {
    subheading: "fue-hair-transplant",
    mainText: "fue-hair-transplant-main",
    descriptions: [
      "fue-hair-transplant-1",
      "fue-hair-transplant-2",
      "fue-hair-transplant-3",
      "fue-hair-transplant-4",
      "fue-hair-transplant-5",
      "fue-hair-transplant-6",
    ],
    imageSrc: FueHairTransplant,
  },
  {
    subheading: "dhi-hair-transplant",
    mainText: "dhi-hair-transplant-main",
    descriptions: ["dhi-hair-transplant-1", "dhi-hair-transplant-2"],
    imageSrc: DhiHairTransplant,
  },
];

const subsections = [
  {
    type: "subsection",
    text: "hair-transplant",
    subheading: "non-surgical-title",
    mainText: "non-surgical-main",
    descriptions: [
      "fue-hair-transplant-1",
      "fue-hair-transplant-2",
      "fue-hair-transplant-3",
      "fue-hair-transplant-4",
      "fue-hair-transplant-5",
      "fue-hair-transplant-6",
    ],
    cards: [
      {
        imageSrc: FueHairTransplant,
        title: "fue-hair-transplant",
        link: "/fue-hair-transplant",
      },
      {
        imageSrc: DhiHairTransplant,
        title: "dhi-hair-transplant",
        link: "/dhi-hair-transplant",
      },
    ],
  },
];

export const HairTransplantPage = () => {
  return (
    <TreatmentPage
      header="hair-transplant"
      treatments={treatments}
      subsections={subsections}
    />
  );
};

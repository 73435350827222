import React from "react";

export const VideoEmbed = ({ url = "", className = "video" }) => {
  return (
    <div
      className={className}
      style={{
        position: "relative",
        paddingBottom: "56.25%",
        paddingTop: 25,
      }}
    >
      <video
        controls
        playsInline
        allowTransparency="false"
        title="Turkey Introduction"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        src={url}
        frameBorder="0"
        preload="none"
      />
    </div>
  );
};

import React from "react";
import EyebrowLiftImage from "../../../images/original/eyebrowlift.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "eyebrow-lift",
    mainText: "eyebrow-lift-main",
    descriptions: [
      "eyebrow-lift-1",
      "eyebrow-lift-2",
      "eyebrow-lift-3",
      "eyebrow-lift-4",
      "eyebrow-lift-5",
      {
        type: "list",
        listTexts: [
          "eyebrow-lift-list-1",
          "eyebrow-lift-list-2",
          "eyebrow-lift-list-3",
          "eyebrow-lift-list-4",
          "eyebrow-lift-list-5",
          "eyebrow-lift-list-6",
          "eyebrow-lift-list-7",
          "eyebrow-lift-list-8",
        ],
      },
      "eyebrow-lift-6",
    ],
    imageSrc: EyebrowLiftImage,
  },
];
export const EyebrowLiftPage = () => {
  return <CustomPage header="eyebrow-lift" details={details} />;
};

import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { IoMdArrowDropdown } from "react-icons/io";
import { css } from "styled-components/macro"; //eslint-disable-line

import logo from "../../images/original/logo.png";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

import US from "country-flag-icons/react/1x1/GB";
import NL from "country-flag-icons/react/1x1/NL";
import ES from "country-flag-icons/react/1x1/ES";
import TR from "country-flag-icons/react/1x1/TR";
import PT from "country-flag-icons/react/1x1/PT";
import IT from "country-flag-icons/react/1x1/IT";
import RO from "country-flag-icons/react/1x1/RO";
import i18n from "i18n.js";
import { Link } from "react-router-dom";
import { useAnimatedNavToggler } from "helpers/useAnimatedNavToggler";

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto relative
`;

export const NavLinks = styled.div`
  display: inline-block;

  @media (min-width: 1000px) {
    width: 700px;
  }
`;

export const NavLink = tw(Link)`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-1/6 mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300 ml-5
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center relative
`;

export const LanguageDropdown = styled.div`
  height: fit-content;
  display: flex;
  z-index: 5;
  position: absolute;
  right: 20px;
  top: 30px;

  @media (max-width: 1020px) {
    top: 65px;
    right: 40px;
  }

  @media (max-width: 700px) {
    top: 60px;
    right: 40px;
  }

  @media (max-width: 560px) {
    top: 55px;
    right: 40px;
  }

  @media (max-width: 480px) {
    top: 50px;
    right: 40px;
  }

  @media (max-width: 420px) {
    top: 45px;
    right: 40px;
  }
`;

export const FlagContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  & > svg {
    width: 30px;
    cursor: pointer;
  }
`;

export const IconContainer = styled.div`
  z-index: 6;
  & > svg {
    cursor: pointer;
  }
`;

const MobileMenuContainer = styled.div`
  display: flex;
  align-items: center;
`;

const selectedLanguage = {
  "tr-TR": TR,
  "en-US": US,
  "it-IT": IT,
  "nl-NL": NL,
  "pt-PT": PT,
  "ro-RO": RO,
  "es-ES": ES,
};

const LanguageDropdownComponent = ({
  isLanguageDropdownOpen,
  setIsLanguageDropdownOpen,
}) => {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsLanguageDropdownOpen(false);
  };

  const SelectedFlag = selectedLanguage[i18n?.language]
    ? selectedLanguage[i18n.language]
    : US;

  return (
    <LanguageDropdown>
      {isLanguageDropdownOpen ? (
        <FlagContainer>
          <US onClick={() => changeLanguage("en-US")} title="United States" />
          <ES onClick={() => changeLanguage("es-ES")} title="Spain" />
          <IT onClick={() => changeLanguage("it-IT")} title="Italy" />
          <NL onClick={() => changeLanguage("nl-NL")} title="Netherlands" />

          <PT onClick={() => changeLanguage("pt-PT")} title="Portugal" />
          <RO onClick={() => changeLanguage("ro-RO")} title="Romania" />
          <TR onClick={() => changeLanguage("tr-TR")} title="Turkey" />
        </FlagContainer>
      ) : (
        <>
          {i18n.language ? (
            selectedLanguage[i18n.language] ? (
              <SelectedFlag style={{ width: "30px", cursor: "pointer" }} />
            ) : (
              <US style={{ width: "30px", cursor: "pointer" }} />
            )
          ) : (
            <US style={{ width: "30px", cursor: "pointer" }} />
          )}
        </>
      )}
      <IconContainer
        onClick={() => {
          setIsLanguageDropdownOpen(!isLanguageDropdownOpen);
        }}
      >
        <IoMdArrowDropdown color="white" width={50} size={20} />
      </IconContainer>
    </LanguageDropdown>
  );
};

export const MainHeader = ({
  links,
  className,
  collapseBreakpointClass = "lg",
}) => {
  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        <LogoLink to="/">
          <img src={logo} alt="logo" />
        </LogoLink>
        {links || <></>}

        <LanguageDropdownComponent
          setIsLanguageDropdownOpen={setIsLanguageDropdownOpen}
          isLanguageDropdownOpen={isLanguageDropdownOpen}
          toggleNavbar={toggleNavbar}
        />
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        <LogoLink to="/">
          <img src={logo} alt="logo" />
        </LogoLink>
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {links || <></>}
        </MobileNavLinks>
        <MobileMenuContainer>
          <LanguageDropdownComponent
            isLanguageDropdownOpen={isLanguageDropdownOpen}
            setIsLanguageDropdownOpen={setIsLanguageDropdownOpen}
            toggleNavbar={toggleNavbar}
          />

          <NavToggle
            onClick={toggleNavbar}
            className={showNavLinks ? "open" : "closed"}
          >
            {showNavLinks ? (
              <CloseIcon tw="w-6 h-6" />
            ) : (
              <MenuIcon tw="w-10 h-10" />
            )}
          </NavToggle>
        </MobileMenuContainer>
      </MobileNavLinksContainer>
    </Header>
  );
};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};

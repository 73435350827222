import React from "react";
import VaserLiposuction from "../../../images/original/vaser.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "vaser-liposuction",
    mainText: "vaser-liposuction-main",
    descriptions: [
      "vaser-liposuction-1",
      "vaser-liposuction-2",
      "vaser-liposuction-3",
      "vaser-liposuction-4",
      "vaser-liposuction-5",
    ],
    imageSrc: VaserLiposuction,
  },
];
export const VaserLiposuctionPage = () => {
  return <CustomPage header="non-surgical-face-lift" details={details} />;
};

import React from "react";
import { createRoot } from "react-dom/client";
import App, { fallbackRender } from "./App";
import Modal from "react-modal";

import "./i18n";
import { ErrorBoundary } from "react-error-boundary";

Modal.setAppElement("#root");

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <ErrorBoundary fallbackRender={fallbackRender}>
    <App />
  </ErrorBoundary>
);

import React from "react";
import EarLiftImage from "../../../images/original/ear_lift.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "ear-lift",
    mainText: "ear-lift-main",
    descriptions: [
      "ear-lift-1",
      "ear-lift-2",
      "ear-lift-3",
      "ear-lift-4",
      "ear-lift-5",
    ],
    imageSrc: EarLiftImage,
  },
];
export const EarLiftPage = () => {
  return <CustomPage header="eyebrow-lift" details={details} />;
};

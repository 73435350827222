import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { MainFooter } from "components/footers/MiniCenteredFooter.js";

import { useEffect } from "react";
import { CustomHero, CustomPageDetail } from "./CustomPage";
import { AnimationRevealPage } from "helpers/AnimationRevealPage";

const Subheading = tw(SectionHeading)`text-center font-bold text-primary-500`;

const Subheader = styled.p`
  text-align: center;
  font-size: 25px;
  font-weight: bold;
`;

const TreatmentContainer = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
export const TreatmentHeading = tw(
  SectionHeading
)`w-full text-center font-bold text-primary-500`;

const TreatmentColumn = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-6 border-2  border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const CardTitle = tw.p`text-gray-700`;

const Treatments = ({ cards, subsections }) => {
  const { t } = useTranslation("common");
  return (
    <>
      <TreatmentContainer>
        <ThreeColumnContainer>
          {cards?.map((card, i) => (
            <TreatmentColumn key={i}>
              <Link to={card.link}>
                <Card>
                  <CardTitle>{t(card.title)}</CardTitle>
                  <img
                    style={{
                      width: "250px",
                      height: "250px",
                      objectFit: "cover",
                    }}
                    src={card.imageSrc}
                    alt=""
                  />
                </Card>
              </Link>
            </TreatmentColumn>
          ))}
        </ThreeColumnContainer>
      </TreatmentContainer>
      {subsections !== undefined &&
        subsections.map((ss) => {
          return (
            <>
              <Subheading>{t(ss.text)}</Subheading>
              <TreatmentContainer>
                <ThreeColumnContainer>
                  {ss.cards?.map((c, i) => (
                    <TreatmentColumn key={i}>
                      <Link to={c.link}>
                        <Card>
                          <CardTitle>{t(c.title)}</CardTitle>
                          <img
                            style={{
                              width: "250px",
                              height: "250px",
                              objectFit: "cover",
                            }}
                            src={c.imageSrc}
                            alt=""
                          />
                        </Card>
                      </Link>
                    </TreatmentColumn>
                  ))}
                </ThreeColumnContainer>
              </TreatmentContainer>
            </>
          );
        })}
    </>
  );
};

export const TreatmentPage = ({
  header,
  details,
  treatments,
  cards,
  subsections,
}) => {
  const { t } = useTranslation("common");
  const { pathname } = useLocation();

  useEffect(() => {
    const canControlScrollRestoration = "scrollRestoration" in window.history;
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <AnimationRevealPage>
      <CustomHero header={header} />

      {treatments?.length > 1 ? (
        <Treatments subsections={subsections} cards={cards} />
      ) : (
        treatments?.map((detail) => {
          if (detail.type === "subheader") {
            return <Subheader>{t(detail.text)}</Subheader>;
          } else {
            return (
              <CustomPageDetail
                key={detail.mainText}
                subheading={detail.subheading}
                mainText={detail.mainText}
                descriptions={detail.descriptions}
                imageSrc={detail.imageSrc}
              />
            );
          }
        })
      )}

      <MainFooter />
    </AnimationRevealPage>
  );
};

import React from "react";
import ZirkonyumImage from "../../../images/original/zirkonyum.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "zirkonyum",
    mainText: "zirkonyum-main",
    descriptions: ["zirkonyum-1", "zirkonyum-2", "zirkonyum-3"],
    imageSrc: ZirkonyumImage,
  },
];
export const Zirkonyum = () => {
  return <CustomPage header="eyebrow-lift" details={details} />;
};

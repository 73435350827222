import React from "react";
import JinekomastiImage from "../../../images/original/jinekomasti.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "jinekomasti",
    mainText: "jinekomasti-main",
    descriptions: [
      "jinekomasti-1",
      "jinekomasti-2",
      "jinekomasti-3",
      "jinekomasti-4",
      "jinekomasti-5",
      "jinekomasti-6",
    ],
    imageSrc: JinekomastiImage,
  },
];
export const JinekomastiPage = () => {
  return <CustomPage header="non-surgical-face-lift" details={details} />;
};

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { Container as ContainerBase } from "components/misc/Layouts.js";
import logo from "../../images/original/logo.png";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { useTranslation } from "react-i18next";
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-20`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`;
const StyledLink = tw(
  Link
)`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;

export const MainFooter = () => {
  const { t } = useTranslation("common");

  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
          </LogoContainer>
          <LinksContainer>
            <StyledLink to="/">{t("home")}</StyledLink>
            <StyledLink to="/about">{t("about")}</StyledLink>
            <StyledLink to="/what-to-do-in-istanbul">
              {t("what-to-do-in-istanbul")}
            </StyledLink>
          </LinksContainer>
          <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/magnuviaconsulting">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://www.instagram.com/magnuviaconsulting/">
              <FaInstagram />
            </SocialLink>
            <SocialLink href="https://wa.me/353830861459">
              <FaWhatsapp />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            &copy;{" "}
            {`Copyright ${new Date().getFullYear()}, Magnuvia All Rights Reserved.`}
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};

import { CustomHero } from "components/CustomPage";
import React from "react";
import { MainFooter } from "components/footers/MiniCenteredFooter.js";
import { AnimationRevealPage } from "helpers/AnimationRevealPage";
import { PopularEvents } from "components/features/VerticalWithAlternateImageAndText";
export const WhatToDoInIstanbul = () => {
  return (
    <AnimationRevealPage>
      <CustomHero header="what-to-do-in-istanbul" />
      <PopularEvents />
      <MainFooter />
    </AnimationRevealPage>
  );
};

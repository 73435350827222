import React from "react";
import ArmLiftImage from "../../../images/original/armlift.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "arm-lift",
    mainText: "arm-lift-main",
    descriptions: [
      "arm-lift-1",
      "arm-lift-2",
      {
        type: "list",
        listTexts: ["arm-lift-list-1", "arm-lift-list-2", "arm-lift-list-3"],
      },

      "arm-lift-3",
      "arm-lift-4",
    ],
    imageSrc: ArmLiftImage,
  },
];
export const ArmLift = () => {
  return <CustomPage header="non-surgical-face-lift" details={details} />;
};

import React from "react";
import BreastSurgery from "../../../images/original/breast_surgery.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "breast-surgery",
    mainText: "breast-surgery-main",
    descriptions: [
      "breast-surgery-1",
      "breast-surgery-2",
      "breast-surgery-3",
      "breast-surgery-4",
    ],
    imageSrc: BreastSurgery,
  },
];
export const BreastSurgeryPage = () => {
  return <CustomPage header="eyebrow-lift" details={details} />;
};

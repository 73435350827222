import React from "react";
import LaminateImage from "../../../images/original/laminate_veneer.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "laminate",
    mainText: "laminate-main",
    descriptions: ["laminate-1", "laminate-2", "laminate-3", "laminate-4"],
    imageSrc: LaminateImage,
  },
];
export const Laminate = () => {
  return <CustomPage header="eyebrow-lift" details={details} />;
};

import React from "react";
import NonSurgicalNeckLift from "../../../images/original/nonsurgical_neck_lift.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "non-surgical-neck-lift",
    mainText: "non-surgical-neck-lift-main",
    descriptions: ["non-surgical-neck-lift-1", "non-surgical-neck-lift-2"],
    imageSrc: NonSurgicalNeckLift,
  },
];
export const NonSurgicalNeckLiftPage = () => {
  return <CustomPage header="eyebrow-lift" details={details} />;
};

import React from "react";
import RinoPlasti from "../../../images/original/rinoplasti.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "rinoplasti",
    mainText: "rinoplasti-main",
    descriptions: [
      "rinoplasti-1",
      {
        type: "list",
        listTexts: [
          "rinoplasti-list-1",
          "rinoplasti-list-2",
          "rinoplasti-list-3",
          "rinoplasti-list-4",
          "rinoplasti-list-5",
        ],
      },
      "rinoplasti-2",
      "rinoplasti-3",
      "rinoplasti-4",
      "rinoplasti-5",
    ],
    imageSrc: RinoPlasti,
  },
];
export const Rinoplasti = () => {
  return <CustomPage header="rinoplasti" details={details} />;
};

import { CustomPage } from "components/CustomPage";
import React from "react";
import ObesitySurgeryImage from "../images/original/tüp mide.jpg";

const details = [
  {
    subheading: "tube-stomach-treatment",
    mainText: "tube-stomach-treatment-main",
    descriptions: [
      "tube-stomach-treatment-1",
      "tube-stomach-treatment-2",
      "tube-stomach-treatment-3",
    ],
    imageSrc: ObesitySurgeryImage,
  },
];

export const TubeStomachTreatment = () => {
  return <CustomPage header="tube-stomach-treatment" details={details} />;
};

import React from "react";
import Bisektomi from "../../../images/original/bisektomi.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "bisektomi",
    mainText: "bisektomi-main",
    descriptions: [
      "bisektomi-1",
      "bisektomi-2",
      "bisektomi-3",
      "bisektomi-4",
      "bisektomi-5",
      "bisektomi-6",
    ],
    imageSrc: Bisektomi,
  },
];
export const BisektomiPage = () => {
  return <CustomPage header="eyebrow-lift" details={details} />;
};

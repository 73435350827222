import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

import Zirkonyum from "../images/original/zirkonyum.jpg";
import Laminate from "../images/original/laminate_veneer.jpg";
import Implant from "../images/original/implant.jpg";
import Whitening from "../images/original/whitening.jpg";
import HollywoodSmile from "../images/original/hollywood.jpg";
import { TreatmentPage } from "components/TreatmentPage";

const treatments = [
  {
    subheading: "zirkonyum",
    mainText: "zirkonyum-main",
    descriptions: ["zirkonyum-1", "zirkonyum-2", "zirkonyum-3"],
    imageSrc: Zirkonyum,
  },
  {
    subheading: "laminate",
    mainText: "laminate-main",
    descriptions: ["laminate-1", "laminate-2", "laminate-3", "laminate-4"],
    imageSrc: Laminate,
  },
  {
    subheading: "implant",
    mainText: "implant-main",
    descriptions: ["implant-1", "implant-2", "implant-3"],
    imageSrc: Implant,
  },
  {
    subheading: "whitening",
    mainText: "whitening-main",
    descriptions: ["whitening-1"],
    imageSrc: Whitening,
  },
  {
    subheading: "hollywood",
    mainText: "hollywood-main",
    descriptions: ["hollywood-1", "hollywood-2", "hollywood-3"],
    imageSrc: HollywoodSmile,
  },
];

const subsections = [
  {
    type: "subsection",
    text: "dental-treatments",
    cards: [
      {
        imageSrc: Zirkonyum,
        title: "zirkonyum",
        link: "/zirkonyum",
      },
      {
        imageSrc: Laminate,
        title: "laminate",
        link: "/laminate",
      },
      {
        imageSrc: Implant,
        title: "implant",
        link: "/implant",
      },
      {
        imageSrc: Whitening,
        title: "whitening",
        link: "/whitening",
      },
      {
        imageSrc: HollywoodSmile,
        title: "hollywood",
        link: "/hollywood",
      },
    ],
  },
];

export const DentalTreatmentsPage = () => {
  return (
    <TreatmentPage
      treatments={treatments}
      header="dental-treatments"
      subsections={subsections}
    />
  );
};

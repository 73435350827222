import { CustomPage } from "components/CustomPage";
import React from "react";
import FueHairTransplant from "../images/original/FUE saç ekimi.jpg";

const details = [
  {
    subheading: "fue-hair-transplant",
    mainText: "fue-hair-transplant-main",
    descriptions: [
      "fue-hair-transplant-1",
      "fue-hair-transplant-2",
      "fue-hair-transplant-3",
      "fue-hair-transplant-4",
      "fue-hair-transplant-5",
      "fue-hair-transplant-6",
    ],
    imageSrc: FueHairTransplant,
  },
];
export const FueHairPage = () => {
  return <CustomPage header="fue-hair-transplant" details={details} />;
};

import React from "react";
import { GlobalStyles } from "styles/GlobalStyles";
import { css } from "styled-components/macro"; //eslint-disable-line

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HomePage } from "demos/AgencyLandingPage";
import { HairTransplantPage } from "demos/HairTransplantPage";
import { ObesitySurgeryPage } from "demos/ObesitySurgeryPage";
import { PlasticSurgeryPage } from "demos/PlasticSurgeryPage";
import { DentalTreatmentsPage } from "demos/DentalTreatments";
import { TubeBabyTreatmentPage } from "demos/TubeBabyTreatment";
import { About } from "pages/About";
import { WhatToDoInIstanbul } from "pages/WhatToDoInIstanbul";
import { FueHairPage } from "pages/FueHairPage";
import { DhiHairPage } from "pages/DhiHairPage";
import { TubeStomachTreatment } from "pages/TubeStomachTreatment";
import { GastricBypass } from "pages/GastricBypass";
import { MiniGastricBypass } from "pages/MiniGastricBypass";
import { GastricBalloon } from "pages/GastricBalloon";
import { Rinoplasti } from "pages/Details/PlasticSurgery/Rinoplasti";
import { FaceLiftPage } from "pages/Details/PlasticSurgery/FaceLift";
import { NonSurgicalFaceLiftPage } from "pages/Details/PlasticSurgery/NonSurgicalFaceLift";
import { EyebrowLiftPage } from "pages/Details/PlasticSurgery/EyebrowLift";
import { EyelidLiftPage } from "pages/Details/PlasticSurgery/EyelidLift";
import { EarLiftPage } from "pages/Details/PlasticSurgery/Earlift";
import { BisektomiPage } from "pages/Details/PlasticSurgery/Bisektomi";
import { SurgicalNeckLiftPage } from "pages/Details/PlasticSurgery/SurgicalNeckLift";
import { NonSurgicalNeckLiftPage } from "pages/Details/PlasticSurgery/NonSurgicalNeckLift";
import { BreastSurgeryPage } from "pages/Details/PlasticSurgery/BreastSurgery";
import { JinekomastiPage } from "pages/Details/PlasticSurgery/Jinekomasti";
import { VaserLiposuctionPage } from "pages/Details/PlasticSurgery/VaserLiposuction";
import { ButtSurgery } from "pages/Details/PlasticSurgery/ButtSurgery";
import { Vajinoplasti } from "pages/Details/PlasticSurgery/Vajinoplasti";
import { TummyTuckPage } from "pages/Details/PlasticSurgery/TummyTuck";
import { ArmLift } from "pages/Details/PlasticSurgery/ArmLift";
import { Zirkonyum } from "pages/Details/DentalTreatments/Zirkonyum";
import { Laminate } from "pages/Details/DentalTreatments/Laminate";
import { Implant } from "pages/Details/DentalTreatments/Implant";
import { Whitening } from "pages/Details/DentalTreatments/Whitening";
import { Hollywood } from "pages/Details/DentalTreatments/Hollywood";

import WpLogo from "./images/WhatsApp.svg";

export function fallbackRender({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}

export default function App() {
  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/hair-transplant" element={<HairTransplantPage />} />
          <Route path="/obesity-surgery" element={<ObesitySurgeryPage />} />
          <Route path="/plastic-surgery" element={<PlasticSurgeryPage />} />
          <Route path="/dental-treatments" element={<DentalTreatmentsPage />} />
          <Route
            path="/tube-baby-treatment"
            element={<TubeBabyTreatmentPage />}
          />
          <Route path="/about" element={<About />} />
          <Route
            path="/what-to-do-in-istanbul"
            element={<WhatToDoInIstanbul />}
          />
          <Route path="/fue-hair-transplant" element={<FueHairPage />} />
          <Route path="/dhi-hair-transplant" element={<DhiHairPage />} />
          <Route
            path="/tube-stomach-treatment"
            element={<TubeStomachTreatment />}
          />
          <Route path="/gastric-bypass" element={<GastricBypass />} />
          <Route path="/mini-gastric-bypass" element={<MiniGastricBypass />} />
          <Route path="/gastric-balloon" element={<GastricBalloon />} />
          <Route path="/rinoplasti" element={<Rinoplasti />} />
          <Route path="/face-lift" element={<FaceLiftPage />} />
          <Route
            path="/non-surgical-facelift"
            element={<NonSurgicalFaceLiftPage />}
          />
          <Route path="/eyebrow-lift" element={<EyebrowLiftPage />} />
          <Route path="/eyelid-lift" element={<EyelidLiftPage />} />
          <Route path="/ear-lift" element={<EarLiftPage />} />
          <Route path="/bisektomi" element={<BisektomiPage />} />
          <Route
            path="/surgical-neck-lift"
            element={<SurgicalNeckLiftPage />}
          />
          <Route
            path="/non-surgical-neck-lift"
            element={<NonSurgicalNeckLiftPage />}
          />
          <Route path="/breast-surgery" element={<BreastSurgeryPage />} />
          <Route path="/gynecomastia" element={<JinekomastiPage />} />
          <Route path="/vaser-liposuction" element={<VaserLiposuctionPage />} />
          <Route path="/butt-surgery" element={<ButtSurgery />} />
          <Route path="/vajinoplasti" element={<Vajinoplasti />} />
          <Route path="/tummy-tuck" element={<TummyTuckPage />} />
          <Route path="/arm-lift" element={<ArmLift />} />
          <Route path="/zirkonyum" element={<Zirkonyum />} />
          <Route path="/laminate" element={<Laminate />} />
          <Route path="/implant" element={<Implant />} />
          <Route path="/whitening" element={<Whitening />} />
          <Route path="/hollywood" element={<Hollywood />} />
        </Routes>
      </Router>

      <div
        style={{
          position: "fixed",
          right: 0,
          bottom: 0,
        }}
      >
        <a href="https://wa.me/353830861459">
          <img style={{ cursor: "pointer" }} width={80} src={WpLogo} />
        </a>
      </div>
    </>
  );
}

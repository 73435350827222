import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import HairTransplant from "../../images/original/FUE saç ekimi.jpg";
import Obesity from "../../images/original/tüp mide.jpg";
import PlasticSurgery from "../../images/original/butt_surgery.jpg";
import Dental from "../../images/original/zirkonyum.jpg";
import Tube from "../../images/original/tüp bebek.jpg";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
export const Heading = tw(
  SectionHeading
)`w-full text-center font-bold text-primary-500`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-6 border-2  border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const CardTitle = tw.p`text-gray-700`;

const cards = [
  {
    imageSrc: HairTransplant,
    title: "hair-transplant",
    link: "/hair-transplant",
  },
  { imageSrc: Obesity, title: "obezite-cerrahisi", link: "/obesity-surgery" },
  {
    imageSrc: PlasticSurgery,
    title: "plastik-cerrahi",
    link: "/plastic-surgery",
  },
  { imageSrc: Dental, title: "diş-tedavileri", link: "/dental-treatments" },
  {
    imageSrc: Tube,
    title: "tüp-bebek-tedavisi",
    link: "/tube-baby-treatment",
  },
];

export const Services = () => {
  const { t } = useTranslation("common");

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>{t("our-services")}</Heading>
        {cards &&
          cards.map((card, i) => (
            <Column key={i}>
              <Link to={card.link}>
                <Card>
                  <CardTitle>{t(card.title)}</CardTitle>
                  <img
                    style={{
                      width: "250px",
                      height: "250px",
                      objectFit: "cover",
                    }}
                    src={card.imageSrc || defaultCardImage}
                    alt=""
                  />
                </Card>
              </Link>
            </Column>
          ))}
      </ThreeColumnContainer>
    </Container>
  );
};

import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

import TubeBabyTreatment from "../images/non-compressed/tube_baby_treatment.jpg";
import { TreatmentPage } from "components/TreatmentPage";

const treatments = [
  {
    subheading: "tube-baby-treatment",
    mainText: "tube-baby-treatment-main",
    descriptions: [
      "tube-baby-treatment-1",
      "tube-baby-treatment-2",
      "tube-baby-treatment-3",
      {
        type: "list",
        listTexts: [
          "tube-baby-treatment-list-1",
          "tube-baby-treatment-list-2",
          "tube-baby-treatment-list-3",
          "tube-baby-treatment-list-4",
        ],
      },
      "tube-baby-treatment-4",
      "tube-baby-treatment-5",
      "tube-baby-treatment-6",
      "tube-baby-treatment-7",
    ],
    imageSrc: TubeBabyTreatment,
  },
];

export const TubeBabyTreatmentPage = () => {
  return <TreatmentPage treatments={treatments} header="tube-baby-treatment" />;
};

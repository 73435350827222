import React from "react";
import ImplantImage from "../../../images/original/implant.jpg";
import { CustomPage } from "components/CustomPage";

const details = [
  {
    subheading: "implant",
    mainText: "implant-main",
    descriptions: ["implant-1", "implant-2", "implant-3"],
    imageSrc: ImplantImage,
  },
];
export const Implant = () => {
  return <CustomPage header="eyebrow-lift" details={details} />;
};

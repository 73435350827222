import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { MainFooter } from "components/footers/MiniCenteredFooter.js";

import {
  NavLink,
  NavLinks,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "./headers/light";
import { useEffect } from "react";
import { AnimationRevealPage } from "helpers/AnimationRevealPage";
import { MainHeader } from "./headers/light";

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-50`;
const StyledHeader = styled(MainHeader)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-primary-500 hover:border-gray-300 hover:text-gray-900 font-bold`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url("images/detailPageHeroImage.jpg");
`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;

const CustomPageContainer = tw.div`relative`;
const CustomPageTwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(
  SectionHeading
)`text-center md:text-left font-bold text-primary-500`;

const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-200`;

const Subheader = styled.p`
  text-align: center;
  font-size: 25px;
  font-weight: bold;
`;

export const CustomPageDetail = ({
  subheading = "about-us",
  textOnLeft = true,
  descriptions = [],
  imageSrc,
  mainText,
}) => {
  const { t } = useTranslation("common");

  return (
    <CustomPageContainer>
      <CustomPageTwoColumn>
        <ImageColumn>
          <Image imageSrc={imageSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{t(subheading)}</Subheading>
            <Description>{t(mainText)}</Description>
            {descriptions &&
              descriptions.length > 0 &&
              descriptions.map((d) => {
                if (d.type === "list") {
                  return (
                    <div>
                      {d.listTexts.map((lt) => (
                        <Description key={lt}>{t(lt)}</Description>
                      ))}
                    </div>
                  );
                } else {
                  return <Description>{t(d)}</Description>;
                }
              })}
          </TextContent>
        </TextColumn>
      </CustomPageTwoColumn>
    </CustomPageContainer>
  );
};

export const CustomHero = () => {
  const { t } = useTranslation("common");
  const navLinks = [
    <NavLinks key={2}>
      <NavLink to="/about">{t("about")}</NavLink>
      <NavLink to="/what-to-do-in-istanbul">
        {t("what-to-do-in-istanbul")}
      </NavLink>
    </NavLinks>,
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
          <LeftColumn></LeftColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};

export const CustomPage = ({ header, details }) => {
  const { t } = useTranslation("common");
  const { pathname } = useLocation();
  useEffect(() => {
    const canControlScrollRestoration = "scrollRestoration" in window.history;
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = "manual";
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <AnimationRevealPage>
      <CustomHero header={header} />

      {details?.map((detail) => {
        if (detail.type === "subheader") {
          return <Subheader>{t(detail.text)}</Subheader>;
        } else {
          return (
            <CustomPageDetail
              key={detail.mainText}
              subheading={detail.subheading}
              mainText={detail.mainText}
              descriptions={detail.descriptions}
              imageSrc={detail.imageSrc}
            />
          );
        }
      })}

      <MainFooter />
    </AnimationRevealPage>
  );
};

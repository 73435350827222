import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

import RinoPlasti from "../images/original/rinoplasti.jpg";
import FaceLift from "../images/original/face-lift.jpg";
import NonSurgicalFaceLift from "../images/original/non_surgical_facelift.jpg";
import EyebrowLift from "../images/original/eyebrowlift.jpg";
import EyelidLift from "../images/original/eyelid_lift.jpg";
import EarLift from "../images/original/ear_lift.jpg";
import Bisektomi from "../images/original/bisektomi.jpg";
import SurgicalNeckLift from "../images/original/surgical_neck_lift.jpg";
import NonSurgicalNeckLift from "../images/original/nonsurgical_neck_lift.jpg";
import BreastSurgery from "../images/original/breast_surgery.jpg";
import Gynecomastia from "../images/original/jinekomasti.jpg";
import VaserLiposuction from "../images/original/vaser.jpg";
import ButtSurgery from "../images/original/butt_surgery.jpg";
import Vajinoplasti from "../images/original/vajinoplasti.jpg";
import TummyTuck from "../images/original/tummy_tuck.jpg";
import ArmLift from "../images/original/armlift.jpg";
import { TreatmentPage } from "components/TreatmentPage";

const treatments = [
  {
    type: "subheader",
    text: "face-surgery",
  },
  {
    subheading: "rinoplasti",
    mainText: "rinoplasti-main",
    descriptions: [
      "rinoplasti-1",
      {
        type: "list",
        listTexts: [
          "rinoplasti-list-1",
          "rinoplasti-list-2",
          "rinoplasti-list-3",
          "rinoplasti-list-4",
          "rinoplasti-list-5",
        ],
      },
      "rinoplasti-2",
      "rinoplasti-3",
      "rinoplasti-4",
      "rinoplasti-5",
    ],
    imageSrc: RinoPlasti,
  },
  {
    subheading: "face-lift",
    mainText: "face-lift-main",
    descriptions: [
      "face-lift-1",
      "face-lift-2",
      "face-lift-3",
      "face-lift-4",
      "face-lift-5",
      "face-lift-6",
      "face-lift-7",
      "face-lift-8",
      "face-lift-9",
    ],
    imageSrc: FaceLift,
  },
  {
    subheading: "non-surgical-facelift",
    mainText: "non-surgical-facelift-main",
    descriptions: [
      "non-surgical-facelift-1",
      "non-surgical-facelift-2",
      {
        type: "list",
        listTexts: [
          "non-surgical-facelift-list-1",
          "non-surgical-facelift-list-2",
          "non-surgical-facelift-list-3",
          "non-surgical-facelift-list-4",
          "non-surgical-facelift-list-5",
          "non-surgical-facelift-list-6",
        ],
      },
      "non-surgical-facelift-3",
      "non-surgical-facelift-4",
    ],
    imageSrc: NonSurgicalFaceLift,
  },
  {
    subheading: "eyebrow-lift",
    mainText: "eyebrow-lift-main",
    descriptions: [
      "eyebrow-lift-1",
      "eyebrow-lift-2",
      "eyebrow-lift-3",
      "eyebrow-lift-4",
      "eyebrow-lift-5",
      {
        type: "list",
        listTexts: [
          "eyebrow-lift-list-1",
          "eyebrow-lift-list-2",
          "eyebrow-lift-list-3",
          "eyebrow-lift-list-4",
          "eyebrow-lift-list-5",
          "eyebrow-lift-list-6",
          "eyebrow-lift-list-7",
          "eyebrow-lift-list-8",
        ],
      },
      "eyebrow-lift-6",
    ],
    imageSrc: EyebrowLift,
  },
  {
    subheading: "eyelid-lift",
    mainText: "eyelid-lift-main",
    descriptions: [
      "eyelid-lift-1",
      "eyelid-lift-2",
      "eyelid-lift-3",
      "eyelid-lift-4",
    ],
    imageSrc: EyelidLift,
  },
  {
    subheading: "ear-lift",
    mainText: "ear-lift-main",
    descriptions: [
      "ear-lift-1",
      "ear-lift-2",
      "ear-lift-3",
      "ear-lift-4",
      "ear-lift-5",
    ],
    imageSrc: EarLift,
  },
  {
    subheading: "bisektomi",
    mainText: "bisektomi-main",
    descriptions: [
      "bisektomi-1",
      "bisektomi-2",
      "bisektomi-3",
      "bisektomi-4",
      "bisektomi-5",
      "bisektomi-6",
    ],
    imageSrc: Bisektomi,
  },
  {
    subheading: "surgical-neck-lift",
    mainText: "surgical-neck-lift-main",
    descriptions: ["surgical-neck-lift-1", "surgical-neck-lift-2"],
    imageSrc: SurgicalNeckLift,
  },
  {
    subheading: "non-surgical-neck-lift",
    mainText: "non-surgical-neck-lift-main",
    descriptions: ["non-surgical-neck-lift-1", "non-surgical-neck-lift-2"],
    imageSrc: NonSurgicalNeckLift,
  },
  {
    type: "subheader",
    text: "breast-surgery",
  },
  {
    subheading: "breast-surgery",
    mainText: "breast-surgery-main",
    descriptions: [
      "breast-surgery-1",
      "breast-surgery-2",
      "breast-surgery-3",
      "breast-surgery-4",
    ],
    imageSrc: BreastSurgery,
  },
  {
    subheading: "jinekomasti",
    mainText: "jinekomasti-main",
    descriptions: [
      "jinekomasti-1",
      "jinekomasti-2",
      "jinekomasti-3",
      "jinekomasti-4",
      "jinekomasti-5",
      "jinekomasti-6",
    ],
    imageSrc: Gynecomastia,
  },
  {
    type: "subheader",
    text: "body-surgery",
  },
  {
    subheading: "vaser-liposuction",
    mainText: "vaser-liposuction-main",
    descriptions: [
      "vaser-liposuction-1",
      "vaser-liposuction-2",
      "vaser-liposuction-3",
      "vaser-liposuction-4",
      "vaser-liposuction-5",
    ],
    imageSrc: VaserLiposuction,
  },
  {
    subheading: "butt-surgery",
    mainText: "butt-surgery-main",
    descriptions: ["butt-surgery-1", "butt-surgery-2"],
    imageSrc: ButtSurgery,
  },
  {
    subheading: "vajinoplasti",
    mainText: "vajinoplasti-main",
    descriptions: [
      "vajinoplasti-1",
      "vajinoplasti-2",
      "vajinoplasti-3",
      "vajinoplasti-4",
      "vajinoplasti-5",
      "vajinoplasti-6",
      "vajinoplasti-7",
    ],
    imageSrc: Vajinoplasti,
  },
  {
    subheading: "tummy-tuck",
    mainText: "tummy-tuck-main",
    descriptions: [
      "tummy-tuck-1",

      {
        type: "list",
        listTexts: [
          "tummy-tuck-list-1",
          "tummy-tuck-list-2",
          "tummy-tuck-list-3",
          "tummy-tuck-list-4",
        ],
      },
      "tummy-tuck-2",
      "tummy-tuck-3",
      "tummy-tuck-4",
      "tummy-tuck-5",
    ],
    imageSrc: TummyTuck,
  },
  {
    subheading: "arm-lift",
    mainText: "arm-lift-main",
    descriptions: [
      "arm-lift-1",
      "arm-lift-2",
      {
        type: "list",
        listTexts: ["arm-lift-list-1", "arm-lift-list-2", "arm-lift-list-3"],
      },

      "arm-lift-3",
      "arm-lift-4",
    ],
    imageSrc: ArmLift,
  },
];

const subsections = [
  {
    type: "subsection",
    text: "face-surgery",
    cards: [
      {
        imageSrc: RinoPlasti,
        title: "rinoplasti",
        link: "/rinoplasti",
      },
      {
        imageSrc: FaceLift,
        title: "face-lift",
        link: "/face-lift",
      },
      {
        imageSrc: NonSurgicalFaceLift,
        title: "non-surgical-facelift",
        link: "/non-surgical-facelift",
      },
      {
        imageSrc: EyebrowLift,
        title: "eyebrow-lift",
        link: "/eyebrow-lift",
      },
      {
        imageSrc: EyelidLift,
        title: "eyelid-lift",
        link: "/eyelid-lift",
      },
      {
        imageSrc: EarLift,
        title: "ear-lift",
        link: "/ear-lift",
      },
      {
        imageSrc: Bisektomi,
        title: "bisektomi",
        link: "/bisektomi",
      },
      {
        imageSrc: SurgicalNeckLift,
        title: "surgical-neck-lift",
        link: "/surgical-neck-lift",
      },
      {
        imageSrc: NonSurgicalNeckLift,
        title: "non-surgical-neck-lift",
        link: "/non-surgical-neck-lift",
      },
    ],
  },
  {
    type: "subsection",
    text: "breast-surgery",
    cards: [
      {
        imageSrc: BreastSurgery,
        title: "breast-surgery",
        link: "/breast-surgery",
      },
      {
        imageSrc: Gynecomastia,
        title: "jinekomasti",
        link: "/gynecomastia",
      },
    ],
  },
  {
    type: "subsection",
    text: "body-surgery",
    cards: [
      {
        imageSrc: VaserLiposuction,
        title: "vaser-liposuction",
        link: "/vaser-liposuction",
      },
      {
        imageSrc: ButtSurgery,
        title: "butt-surgery",
        link: "/butt-surgery",
      },
      {
        imageSrc: Vajinoplasti,
        title: "vajinoplasti",
        link: "/vajinoplasti",
      },
      {
        imageSrc: TummyTuck,
        title: "tummy-tuck",
        link: "/tummy-tuck",
      },
      {
        imageSrc: ArmLift,
        title: "arm-lift",
        link: "/arm-lift",
      },
    ],
  },
];

export const PlasticSurgeryPage = () => {
  return (
    <TreatmentPage
      treatments={treatments}
      header="plastic-surgery"
      subsections={subsections}
    />
  );
};
